// React
import { useContext, useState } from 'react'
// Routing
import { Link } from 'react-router-dom'
// 3rd Party - React Bootstrap Table2 - Filter
import { textFilter } from 'react-bootstrap-table2-filter'
// MainStem - UI
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderSubtitle,
  CardHeaderTitle,
  FormattedNumber,
  Label,
  Modal,
  ModalBody,
  Spinner,
  Table,
  theme,
  toast,
  useOnFirstLoad
} from '@mainstem/react-mainstem'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/pro-light-svg-icons'
// MainStem - API
import { Api, MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeAPIRequest } from 'api-new'
// Global - Components
import { LeafLogixLookup } from 'components'
// Global - Context
import { AppContext } from 'context'
// Global - Helpers
import { LeafLogixTypeAsString, loadLeafLogixAggregates } from 'helpers'
// Global - Types
import { LeafLogixTypes } from 'types'
// Local - Types
import { ILeafLogixRecordDetail } from './types'

export const LeafLogixRecordDetail = ({ recordID, type }: ILeafLogixRecordDetail) => {
  const mainStemAPI = new Api()
  const { loggedInUser } = useContext(AppContext)

  const [loadingLeafLogixAggregate, setLoadingLeafLogixAggregate] = useState<boolean>(false)
  const [leafLogixAggregate, setLeafLogixAggregate] = useState<any>([])
  const [leafLogixIDs, setLeafLogixSelections] = useState<any[]>([])
  const [initialLeafLogixIDs, setInitialLeafLogixSelections] = useState<any[]>([])

  const loadAggregateDetails = () => {
    if (loggedInUser.organization?.integrations?.leafLogix?.enabled) {
      setLeafLogixSelections([])
      setInitialLeafLogixSelections([])
      setLeafLogixAggregate([])
      loadLeafLogixAggregates(
        loggedInUser.organization?.integrations?.leafLogix?.customerIntegrationIDs ?? [],
        recordID,
        type,
        setLoadingLeafLogixAggregate,
        setLeafLogixAggregate
      )
    }
  }

  const handleSaveLeafLogixSelection = () => {
    setLoadingLeafLogixAggregate(true)
    const apiRequest = {
      type: type ? LeafLogixTypeAsString(type) : undefined,
      recordID: recordID,
      updates: leafLogixIDs.map((x) => {
        if (x.value && typeof x.value === 'number') {
          x.value = x.value.toString()
        }
        return {
          leafLogixID: x.value,
          customerIntegrationSettingID: x.customerIntegrationID
        }
      })
    } as MainStemAPIControllersPurchasingIntegrationLeafLogixSetByTypeAPIRequest
    return mainStemAPI.api
      .integrationLeafLogixSetByType(apiRequest)
      .then((response) => {
        if (response.data?.wasSuccessful) {
          toast.success('LeafLogix ID was successfully updated.')
        } else if (response.data?.message) {
          toast.error(response.data.message)
        } else {
          toast.error('Failed to update LeafLogix ID.')
        }
        loadAggregateDetails()
        return response
      })
      .catch((error) => {
        toast.error(error)
        return error
      })
      .finally(() => {
        setLoadingLeafLogixAggregate(false)
      })
  }

  const handleOnSelected = (value: any, customerIntegrationID: any) => {
    // Always push new items -- then call distinct.
    const copy = [...leafLogixIDs]
    if (copy.find((x) => x.customerIntegrationID == customerIntegrationID)) {
      copy.splice(
        copy.findIndex((x) => x.customerIntegrationID == customerIntegrationID),
        1
      )
    }
    copy.push({
      customerIntegrationID: customerIntegrationID,
      value: value
    })
    setLeafLogixSelections(copy)
  }

  useOnFirstLoad(() => {
    loadAggregateDetails()
  })

  return (
    <>
      {loggedInUser.organization?.integrations?.leafLogix?.enabled && (
        <Card>
          <CardHeader
            actions={
              <CardHeaderActions>
                <Button
                  onClick={() => {
                    loadAggregateDetails()
                  }}
                >
                  <FontAwesomeIcon icon={faRefresh} />
                  &nbsp;Refresh
                </Button>
                <Button icon={theme.icons.save} onClick={handleSaveLeafLogixSelection}>
                  Save
                </Button>
              </CardHeaderActions>
            }
            subtitle={
              <CardHeaderSubtitle>
                Map Your MainStem&nbsp;
                {type === LeafLogixTypes.Vendors
                  ? `Suppliers`
                  : type === LeafLogixTypes.Rooms
                  ? `Departments`
                  : 'Products'}
                &nbsp;to Dutchie's&nbsp;
                {type === LeafLogixTypes.Vendors ? `Vendors` : type === LeafLogixTypes.Rooms ? `Rooms` : 'Products'}
              </CardHeaderSubtitle>
            }
            title={
              <CardHeaderTitle>
                <img src={theme.logos.dutchie} style={{ height: 30 }} />
                &nbsp;
                <FontAwesomeIcon icon={theme.icons.new} />
                &nbsp;
                <img src={theme.logos.mainstem.color} style={{ height: 30 }} />
              </CardHeaderTitle>
            }
          />
          <CardBody>
            {loadingLeafLogixAggregate ? (
              <>
                <Alert color='danger' title='Please Be Patient'>
                  <div>
                    <p>
                      We are currently contacting multiple API's at Dutchie so we can aggregate data about your
                      products, rooms, departments, and inventory. This series of transactions{' '}
                      <strong>can take up to 3 minutes to complete</strong>. Please keep this tab open and wait for the
                      results to display below.
                    </p>
                    <p>
                      <Spinner />
                      <span>&nbsp;Currently loading data from the Dutchie API's...</span>
                    </p>
                  </div>
                </Alert>
              </>
            ) : (
              <>
                {leafLogixAggregate && leafLogixAggregate.customerIntegrationInventory && (
                  <>
                    {leafLogixAggregate.customerIntegrationInventory?.map((inventoryAggregate: any, index: number) => {
                      const customerIntegration = loggedInUser.organization?.integrations?.leafLogix?.integrationDetails?.find(
                        (x) => x.id == inventoryAggregate.customerIntegrationID
                      )
                      const recordAggregation = inventoryAggregate?.aggregateByType?.find(
                        (x: any) => x.recordID == recordID
                      )
                      return (
                        <div key={index}>
                          <div>
                            <Label>
                              MainStem Integration #{customerIntegration?.id} : {customerIntegration?.name}
                            </Label>
                          </div>
                          <LeafLogixLookup
                            customerIntegrationID={customerIntegration?.id}
                            defaultValue={
                              leafLogixIDs.find((i) => i.customerIntegrationID == customerIntegration?.id) ?? undefined
                            }
                            onSelected={(value: any) => {
                              handleOnSelected(value, customerIntegration?.id)
                            }}
                            recordID={recordID}
                            setInitialLeafLogixID={(value: any) => {
                              const copy = [...initialLeafLogixIDs]
                              const found = copy.find((x: any) => x.customerIntegrationID === customerIntegration?.id)
                              if (found) {
                                found.value = value
                              } else {
                                copy.push({
                                  customerIntegrationID: customerIntegration?.id,
                                  value: value
                                })
                              }
                              setInitialLeafLogixSelections(copy)
                            }}
                            type={type}
                          />
                          <div style={{ paddingTop: '5px' }}>
                            <Label>Total Inventory Count:</Label>
                            &nbsp;
                            <strong>
                              <FormattedNumber value={recordAggregation?.totalQuantity ?? 0} />
                            </strong>
                          </div>
                          {recordAggregation?.inventory?.length > 0 && (
                            <>
                              {type !== LeafLogixTypes.Products && (
                                <>
                                  <div>
                                    <Label>
                                      Over&nbsp;
                                      <strong>
                                        <FormattedNumber value={recordAggregation?.distinctProducts ?? 0} />
                                      </strong>
                                      &nbsp;Distinct Product
                                      {recordAggregation.distinctProducts > 1 ? 's' : ''}
                                    </Label>
                                  </div>
                                </>
                              )}
                              {type !== LeafLogixTypes.Vendors && (
                                <>
                                  <div>
                                    <Label>
                                      Supplied from&nbsp;
                                      <strong>
                                        <FormattedNumber value={recordAggregation?.distinctVendors ?? 0} />
                                      </strong>
                                      &nbsp;Supplier
                                      {recordAggregation.distinctVendors > 1 ? 's' : ''}
                                    </Label>
                                  </div>
                                </>
                              )}
                              {type !== LeafLogixTypes.Rooms && (
                                <>
                                  <div>
                                    <Label>
                                      Stocked in&nbsp;
                                      <strong>
                                        <FormattedNumber value={recordAggregation.distinctRooms ?? 0} />
                                      </strong>
                                      &nbsp;Department
                                      {recordAggregation.distinctRooms > 1 ? 's' : ''}
                                    </Label>
                                  </div>
                                </>
                              )}
                              <LeafLogixAggregateView
                                customerIntegration={customerIntegration}
                                details={recordAggregation}
                                type={type}
                              />
                            </>
                          )}
                          {leafLogixAggregate?.aggregate && leafLogixAggregate.customerIntegrationInventory.length > 1 && (
                            <>
                              <hr />
                            </>
                          )}
                        </div>
                      )
                    })}

                    {leafLogixAggregate?.aggregate && leafLogixAggregate.customerIntegrationInventory.length > 1 && (
                      <>
                        {/* <pre>
                            {JSON.stringify(leafLogixAggregate.aggregate, null, 2)}
                          </pre> */}
                        <div style={{ paddingTop: '5px' }}>
                          <Label>Total Inventory Count:</Label>
                          &nbsp;
                          <strong>
                            <FormattedNumber value={leafLogixAggregate?.aggregate?.totalQuantity ?? 0} />
                          </strong>
                        </div>
                        {type !== LeafLogixTypes.Products && (
                          <>
                            <div>
                              <Label>
                                Over&nbsp;
                                <strong>
                                  <FormattedNumber value={leafLogixAggregate?.aggregate?.distinctProducts ?? 0} />
                                </strong>
                                &nbsp;Distinct Product
                                {leafLogixAggregate?.aggregate?.distinctProducts > 1 ? 's' : ''}
                              </Label>
                            </div>
                          </>
                        )}
                        {type === LeafLogixTypes.Rooms && (
                          <div>
                            <Label>
                              Supplied From&nbsp;
                              <strong>
                                <FormattedNumber value={leafLogixAggregate?.aggregate?.distinctVendors ?? 0} />
                              </strong>
                              &nbsp;Vendor
                              {leafLogixAggregate?.aggregate?.distinctVendors > 1 ? 's' : ''}
                            </Label>
                          </div>
                        )}
                        {type !== LeafLogixTypes.Rooms && (
                          <>
                            <div>
                              <Label>
                                Stocked in&nbsp;
                                <strong>
                                  <FormattedNumber value={leafLogixAggregate?.aggregate?.distinctRooms ?? 0} />
                                </strong>
                                &nbsp;Department
                                {leafLogixAggregate?.aggregate?.distinctRooms > 1 ? 's' : ''}
                              </Label>
                            </div>
                            <LeafLogixAggregateView details={leafLogixAggregate?.aggregate} type={type} />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </CardBody>
        </Card>
      )}
    </>
  )
}

interface ILeafLogixAggregateView {
  customerIntegration?: any
  details: any
  type?: LeafLogixTypes
}

export const LeafLogixAggregateView = ({ customerIntegration, details }: ILeafLogixAggregateView) => {
  const [showModal, setShowModal] = useState(false)

  /* ======================
            return
    ====================== */

  return (
    <>
      <Button
        color='secondary'
        onClick={() => {
          setShowModal(true)
        }}
      >
        View Details
      </Button>
      {showModal && (
        <Modal
          modalDialogStyle={{
            width: 'calc(100vw - 10vw)',
            maxWidth: 'calc(100vw - 10vw)'
          }}
          onClose={() => {
            setShowModal(false)
          }}
        >
          <ModalBody>
            <h5
              style={{
                textAlign: 'left'
              }}
            >
              <img src={theme.logos.dutchie} style={{ height: 30 }} />
              <p
                style={{
                  color: '#333',
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: 1,
                  marginBottom: 25,
                  textAlign: 'center',
                  textTransform: 'uppercase'
                }}
              >
                Inventory for&nbsp;{details.recordName}
                <br />
                {customerIntegration && (
                  <Label>
                    {customerIntegration.id} {customerIntegration.name}
                  </Label>
                )}
              </p>
            </h5>
            <Table columns={leafLogixInventoryColumnsByProduct} data={details.inventory} title='Inventory By Product' />
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
                justifyContent: 'center',
                marginTop: 25
              }}
            >
              <Button
                color='danger'
                onClick={() => {
                  setShowModal(false)
                }}
                style={{ minWidth: 150 }}
              >
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export const leafLogixInventoryColumnsByProduct = [
  {
    dataField: 'leafLogixProductName',
    headerText: 'Dutchie Product',
    sort: true,
    filter: textFilter(),
    formatter: (field: any, data: any) => {
      return (
        <>
          [{data.leafLogixProductID}]&nbsp;{data.leafLogixProductName}
        </>
      )
    }
  },
  {
    dataField: 'leafLogixRoomName',
    headerText: 'Dutchie Room',
    sort: true,
    filter: textFilter(),
    formatter: (field: any, data: any) => {
      return (
        <>
          [{data.leafLogixRoomID}]&nbsp;{data.leafLogixRoomName}
        </>
      )
    }
  },
  {
    dataField: 'leafLogixVendorName',
    headerText: 'Dutchie Vendor',
    sort: true,
    filter: textFilter(),
    formatter: (field: any, data: any) => {
      return (
        <>
          [{data.leafLogixVendorID}]&nbsp;{data.leafLogixVendorName}
        </>
      )
    }
  },
  {
    dataField: 'quantity',
    headerText: 'Quantity',
    sort: true,
    filter: textFilter()
  },
  {
    dataField: 'departmentName',
    headerText: 'Department',
    formatter: (field: any, data: any) => {
      return (
        <>
          {data.departmentID ? (
            <Link to={`/departments/details/${data.departmentID}`}>{data.departmentName}</Link>
          ) : (
            <>Not Linked</>
          )}
        </>
      )
    }
  },
  {
    dataField: 'supplierName',
    headerText: 'Supplier',
    formatter: (field: any, data: any) => {
      return (
        <>
          {data.supplierID ? (
            <Link to={`/suppliers/details/${data.supplierID}`}>{data.supplierName}</Link>
          ) : (
            <>Not Linked</>
          )}
        </>
      )
    }
  },
  {
    dataField: 'productName',
    headerText: 'Product',
    formatter: (field: any, data: any) => {
      return (
        <>
          {data.productID ? (
            <Link to={`/products/details/${data.productID}`}>{data.productName}</Link>
          ) : (
            <>Not Linked</>
          )}
        </>
      )
    }
  }
] as any
